jQuery(function($) {
  $('#mobile-menu').click(function(){
    $('header').toggleClass('mobile-active');

    if($('header').hasClass('mobile-active')) {
      $('body, html').css('overflow-y', 'hidden');
    } else {
      $('body, html').css('overflow-y', 'auto');
    }
  });
});